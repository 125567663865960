import React from 'react';
import './Services.css';
import HeartEmoji from "../../img/heartemoji.png";
import Glasses from "../../img/glasses.png";
import Humble from "../../img/humble.png";
import Card from '../Card/Card';
import Resume from './ramresume.pdf';
import { useContext } from "react";
import { themeContext } from "../../Context";
import { motion } from "framer-motion";


const Services = () => {
    const theme = useContext(themeContext);
    const darkMode = theme.state.darkMode;
    const transition = {
        duration: 1,
        type: "spring",
      };
  return (
    <div className="services" id='Services'>
        <div className="awesome">
           <span style={{ color: darkMode ? "white" : "" }}>My Awesome</span>
            <span>services</span>
            
            <span>
            Hello there! I'm a web designer & developer, <br/>
            and I'm very passionate and dedicated to my work.<br/>  
            I have acquired the skills and knowledge necessary to make your project a success.<br/> 
            I enjoy every step of the design and development process, <br/>
            from discussion and collaboration.
            </span>
            <a href={Resume} download>
            <button className="button1 s-button">Download CV</button>
            </a>
            
            <div className="blur s-blur1" style={{ background: "#ABF1FF94" }}></div>
        </div>
        <div className="cards">
            <motion.div
            initial={{ left: "25rem" }}
            whileInView={{ left: "14rem" }}
            transition={transition}
            >
                <Card 
                emoji = {HeartEmoji}
                heading= {'Design'}
                detail = {"Figma, Photoshop, Adobe xd, After Effects"}
                />
            </motion.div>
             <motion.div
             initial={{ left: "-11rem", top: "12rem" }}
             whileInView={{ left: "-4rem" }}
             transition={transition}
             >
                <Card 
                emoji = {Glasses}
                heading= {'Developer'}
                detail = {"Html, Css, JavaScript, React Js, Bootstrap"}
                />
            </motion.div>
            <motion.div
            initial={{ top: "19rem", left: "25rem" }}
            whileInView={{ left: "12rem" }}
            transition={transition}
            >
                <Card 
                emoji = {Humble}
                heading= {'UI/UX'}
                detail = {
                    "Web Developer using JavaScript, React Js, CSS3, and HTML5"
                }
                color="rgba(252, 166, 31, 0.45)"
                />
                <div className="blur s-blur2" style={{ background: "var(--purple)" }} ></div>
            </motion.div>
        </div>
    </div>
  )
}

export default Services;