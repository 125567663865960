import React from 'react';
import './Works.css';
import Kwikbox from "../../img/kwikboxp.png";
import Kwikpay from "../../img/kwikpp.png";
import Kwseller from "../../img/kwsp.png";
import Dynor from "../../img/dynop.png";
import Grancer from "../../img/grancep.png";
import { useContext } from "react";
import { themeContext } from "../../Context";
import {Link} from 'react-scroll';
import { motion } from "framer-motion";

const Works = () => {
    const theme = useContext(themeContext);
    const darkMode = theme.state.darkMode;
  return (
    <div className="works" id='Works'>
        <div className="w-left">
        <div className="awesome">
            <span style={{color: darkMode?'white': ''}}>Worsks for All these</span>
            <span>Brands & Clients</span>
            <span>
            <h1>What I Do For Clients and Brands</h1>
            <br/>
            Most common methods for designing and development websites
            <br/> 
            that work well on desktop is responsive website and adaptive design.
            <br />
                
                
            </span>
            <Link to="Contact" smooth={true} spy={true}>
            <button className='button s-button'>Hire me</button>
            </Link>
            
            <div className="blur s-blur1" style={{ background: '#ABF1FF94' }}></div>
        </div>
        </div>
        <div className="w-right">
           <motion.div
            initial={{ rotate: 45 }}
            whileInView={{ rotate: 0 }}
            viewport={{ margin: "-40px" }}
            transition={{ duration: 3.5, type: "spring" }}
            className="w-mainCircle"
            >
                <div className="w-secCircle">
                    <img src={Kwikbox} alt='' />
                </div>
                <div className="w-secCircle">
                    <img src={Kwikpay} alt='' />
                </div>
                <div className="w-secCircle">
                    <img src={Kwseller} alt='' />
                </div>
                <div className="w-secCircle">
                    <img src={Dynor} alt='' /> 
                </div>
                <div className="w-secCircle">
                    <img src={Grancer} alt='' />
                </div>
            </motion.div>
            <div className="w-backCircle blueCircle"></div>
            <div className="w-backCircle yellowCircle"></div>
        </div>
    </div>
  )
}

export default Works;