import React from 'react';
import "./Testimonial.css";
import { Swiper, SwiperSlide } from "swiper/react";
import profilePic1 from "../../img/profile1.jpg";
import profilePic2 from "../../img/profile2.jpg"
import profilePic3 from "../../img/profile3.jpg";
import profilePic4 from "../../img/profile4.jpg";
import { Pagination } from "swiper/modules";
import "swiper/css/pagination";
import "swiper/css";


const Testimonial = () => {
    const clients = [
        {
          img: profilePic1,
          review:
            "Loved the template design and website development, documentation, customizability and the customer support from Ramrk team! I am a noob in programming with very little knowledge about coding but the Ramrk team helped me to launch my resume website successfully. Much recommended!",
        },
        {
          img: profilePic2,
          review:
            "I rarely like to write reviews, but the Ramrk team truly deserve a standing ovation for their customer support, customisation and most importantly, friendliness and professionalism. Many thanks once again for everything and hope that I get to deal with you again in the near future.",
        },
        {
          img: profilePic3,
          review:
            "Really the Code Quality, Customer Support, and design are awesome and its good support they are giving. They give an instant solution to our needs. Really awesome. I will strongly recommend to my friends. Simply amazing team ramrk! Thank you from Ramrk and a good developer & designer!",
        },
        {
          img: profilePic4,
          review:
            "Amazing works for my Business and portfolio for Both Website development and designing, for animations parts too awesome! looking so good to everyone. Many thanks once again for everything and hope that I get to deal with you again in the near future. Thank you Ramrk!",
        },
      ];
  return (
    <div className="t-wrapper" id='Testimonials'>
        <div className="t-heading">
            <span>Clients always get</span>
              
            <span>Exceptional work</span>

            <span>for me...</span>
            <div className="blur t-blur1" style={{ background: "var(--purple)" }}></div>
            <div className="blur t-blur2" style={{ background: "skyblue" }}></div>
            
            
        </div>
        <Swiper
        modules={[Pagination]}
        slidesPerView={1}
        pagination={{clickable: true}}
        
        >
           {clients.map((client, index)=> {
            return(
                <SwiperSlide key={index}>
                    <div className="testimonial">
                    <img src={client.img} alt="" />
                    <span>{client.review}</span>
                    </div>
                </SwiperSlide>
            )
           })}
        </Swiper>
    </div>
  )
}

export default Testimonial;