
import './Intro.css';
import Github from '../../img/github.png';
import LinkedIn from '../../img/linkedin.png';
import Instagram from '../../img/instagram.png';
import Vector11 from '../../img/Vector11.png';
import Vector22 from '../../img/Vector22.png';
import ramimg from '../../img/ramimg.png';
import FloatingDiv from '../FloatingDiv/FloatingDiv';
import thumbup from '../../img/thumbup.png';
import crown from '../../img/crown.png';
import glassesimoji from '../../img/glassesimoji.png';
import { useContext } from "react";
import { themeContext } from "../../Context";
import React from "react";
import {motion} from "framer-motion";
import { Link } from "react-scroll";

const Intro=()=> {
    
    const transition = {duration: 2, type: 'spring'};


    const theme = useContext(themeContext);
    const darkMode = theme.state.darkMode;
    return (
    <div className="Intro" id='Intro'>
        <div className="i-left">
            <div className="i-name">
                <span style={{color: darkMode? 'white': ''}}>Hi! I Am</span>
                <span>Ramakrishna Raghumandala</span>
                <span>Frontend Developer with high level of experience in web designing and development, <br/>producting the Quality work</span>
            </div>
            <Link to="Contact" smooth={true} spy={true}>
            <button className="button i-button">Hire me</button>
            </Link>
            <div className="i-icons">
                <a href='https://github.com/Ramnua' target='blank'><img src={Github} alt='' /></a>
                <a href='https://www.linkedin.com/in/ramakrishna-raghumandala-344861266' target='blank'><img src={LinkedIn} alt='' /></a>
                <a href='https://www.instagram.com/rock_star_ram3112/' target='blank'><img src={Instagram} alt='' /></a>
                
            </div> 
        </div>
        <div className="i-right">
            <img src={Vector11} alt=''/>
            <img src={Vector22} alt=''/>
            <img src={ramimg} alt=''/>
            <motion.img
            initial={{ left: "-36%" }}
            whileInView={{ left: "-24%" }}
            transition={transition}
            src={glassesimoji} alt=''/>
           <motion.div
           initial={{ top: "-9%", left: "58%" }}
           whileInView={{ left: "74%" }}
           transition={transition}
           className="floating-div"
            >
                <FloatingDiv image={crown} txt1='Web' txt2='Developer'/>
            </motion.div>
            <motion.div
            initial={{ left: "10rem", top: "16rem" }}
            whileInView={{ left: "0rem" }}
            transition={transition}
            className="floating-div"
            >
                <FloatingDiv image={thumbup} txt1='Best UI' txt2='Designer'/>
            </motion.div>
            <div className='blur' style={{ background: "rgb(238 210 255"}}></div>
            <div className='blur'
                style={{
                    background: "#C1F5FF",
                    top: '17rem',
                    width: "21rem",
                    height: "11rem",
                    left: "-9rem",
                }}>
            </div>
        </div>
    </div>
  )
}

export default Intro;